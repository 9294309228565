@use '@americastestkitchen/mise/mise-styles/mise.scss';
.twoUpDocCardCarouselWrapper {
  #carouselArea {
    grid-auto-columns: 275px !important;
    gap: 16px !important;

    @media screen and (min-width: mise.breakpoint(md)) {
      grid-auto-columns: 560px !important;
      gap: 21px !important;
    }
  }
}
