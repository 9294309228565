@use '@americastestkitchen/mise/mise-styles/mise.scss';

.standardDocCardWrapper {
  border-radius: 6px;
}

.standardDocCardImage {
  height: 162px;
  margin-bottom: mise.spacing(x3);

  @media screen and (min-width: mise.breakpoint(lg)) {
    height: 274px;
  }
}
.image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.standardDocCardHeadline {
  @include mise.setFont(proximaNova, null, null);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  @media screen and (min-width: mise.breakpoint(md)) {
    font-size: 22px;
    line-height: 25px;
  }
}
