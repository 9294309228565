@use "../../../../../styles/mise.scss";

.aspectRatioWrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border: none;
  }
}
