.loader {
  width: 80px;
  height: 80px;
  border: 10px solid #A92E14;
  border-bottom-color: transparent;
  border-radius: 100%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  right: 50%;
  top: 50%;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(260deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
