@use '@americastestkitchen/mise/mise-styles/mise.scss';

.authors {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &--is-light {
    .author-names {
      color: mise.sourceColor(white);
    }
  }

  &--is-dark {
    .author-names {
      color: mise.sourceColor(caviar);
    }
  }

  &--has-two {
    .author-image-list__item+.author-image-list__item {
      margin-left: calc(mise.spacing(x1) * -1);
    }
  }

  &--has-many {
    .author-image-list__item+.author-image-list__item {
      margin-left: calc(mise.spacing(x2) * -1);
    }
  }
}

.author-image-list {
  display: flex;

  &__item {
    position: relative;
    z-index: mise.zIndex(page, 2);

    &:first-child {
      z-index: mise.zIndex(page, 3);
    }

    &:last-child {
      z-index: mise.zIndex(page, 1);
    }
  }
}

.author-image-link {
  display: block;
}

.author-names {
  @include mise.setFont(proximaNova, null, null);
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  line-height: 1.125;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}