@use '@americastestkitchen/mise/mise-styles/mise.scss';

.processCardContainer {
  display: flex;
  gap: 11px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  >div {
    flex: 0 0 auto;
    scroll-snap-align: start;
    text-align: center;
    width: 275px;
    min-height: 683px;
    border-radius: 6px;
    border: 1px solid mise.sourceColor(roseShade);
    overflow: hidden;
  }

  .contentWrapper {
    padding: 22px 16px;
    text-align: left;

    .headline {
      @include mise.setFont(moret, null, null);
      font-size: 27px;
      line-height: 32px;
      margin-bottom: 7px;
    }

    .copy {
      @include mise.setFont(proximaNova, sm, normal);
      font-size: 16px;
      line-height: 24px;

      a {
        text-underline-offset: 4px;
        text-decoration: underline solid mise.sourceColor(caviar) 1px;
        text-decoration-line: underline; // safari
      }
    }
  }

  .imageWrapper {
    width: 100%;
    height: 458px;

    .image {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.toggleWrapper {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  margin-bottom: 22px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 23px;
  }
  @media screen and (min-width: mise.breakpoint(lg)) {
    margin-bottom: 24px;
  }
}

.toggleButton {
  text-align: center;
  position: relative;
  @include mise.setFont(proximaNova, null, null);
  font-weight: 700;
  line-height: 22.4px;

  @media screen and (min-width: mise.breakpoint(sm)) {
    width: 274px;
  }

  p {
    padding: 0 16px 10px 16px;
  }
}

.toggleButton.active {
  p {
    color: mise.sourceColor(tomato);
  }

  &::before {
    content: "";
    position: absolute;
    left: 30%;
    bottom: 0;
    height: 15px;
    width: 40%;
    border-bottom: 3px solid mise.sourceColor(tomato);
  }
}

@media screen and (min-width: 1136px) {
  .processCardContainer {
    gap: 17px;
    margin-inline: auto;

    >div {
      flex: 1;
      width: initial;
      max-width: unset;
    }
  }
}
