@use '@americastestkitchen/mise/mise-styles/mise.scss';

.standardDocCardCarouselWrapper {
  &.carouselArea {
    grid-auto-columns: 162px !important;
    gap: 16px !important;
    
    @media screen and (min-width: 768px) {
      grid-auto-columns: 274px !important;
      gap: 13.65px !important;
    }
  }
}
