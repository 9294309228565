.imageGridWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .image1,
  .image2 {
    flex-basis: 100%;
  }
  .image3,
  .image4 {
    flex-basis: calc(50% - 8px);
  }
}

@media screen and (min-width: 768px) {
  .imageGridWrapper {
    display: grid;
    grid-template-areas:
      'image1 image2 image2'
      'image1 image3 image4';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    div {
      background: #000;
      display: flex;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    .image1 {
      grid-area: image1;
    }
    .image2 {
      grid-area: image2;
    }
    .image3 {
      grid-area: image3;
    }
    .image4 {
      grid-area: image4;
    }
  }
}