@use '@americastestkitchen/mise/mise-styles/mise.scss';

.testimonials {
  background-color: mise.sourceColor(beet);
  color: #fff;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  height: 324px;

  @media screen and (min-width: mise.breakpoint(lg)) {
    height: 400px;
  }

  .testimonialContent {
    width: 100%;
  }

  .testimonial,
  .citation {
    @include mise.setFont(moret, null, null);
    margin-inline: auto;
  }

  .testimonial {
    font-size: 32px;
    line-height: 36px;
    width: 80%;

    @media screen and (min-width: mise.breakpoint(md)) {
      font-size: 48px;
      line-height: 54px;
      width: 90%;
    }
    @media screen and (min-width: mise.breakpoint(lg)) {
      font-size: 62px;
      line-height: 62px;
      width: 70%;
    }
  }

  .activeItems {
    width: 100%;
    position: absolute;
    bottom: 33px;

    @media screen and (min-width: mise.breakpoint(md)) {
      bottom: 36px;
    }
    @media screen and (min-width: mise.breakpoint(lg)) {
      bottom: 50px;
    }
  }

  .citation {
    font-size: 24px;
    font-style: italic;
    line-height: 44px;

    @media screen and (min-width: mise.breakpoint(md)) {
      font-size: 30px;
      line-height: 74px;
    }
  }

  .citation span {
    font-style: normal;
  }

  .focus-active-items {
    display: flex;
    gap: 8px;
    justify-content: center;

    button {
      background-color: #fff;
      border-radius: 50%;
      color: #000;
      height: 10px;
      max-width: 10px;
      transition: max-width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
      width: 30px;

      &:focus {
        outline-color: white;
      }

      &.active {
        background-color: mise.sourceColor(tomato);
        border-radius: 10em;
        color: white;
        max-width: 30px;
      }
    }
  }

  .directional-active-items {
    bottom: 23px;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: absolute;
    right: 23px;

    svg {
      height: 100%;
      width: 100%;
    }

    @media screen and (min-width: mise.breakpoint(md)) {
      bottom: 26px;
      gap: 12px;
      right: 132px;
    }
    @media screen and (min-width: mise.breakpoint(lg)) {
      bottom: 40px;
    }

    button {
      height: 25px;
      width: 25px;

      @media screen and (min-width: mise.breakpoint(md)) {
        height: 32px;
        width: 32px;
      }
    }
  }
}
