@use '@americastestkitchen/mise/mise-styles/mise.scss';
.mainPageWrapper {
  background-color: #fff8f5;
  max-width: 100vw;
}
.bodyContentWrapper {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1380px) {
    width: 1380px;
  }
}
