@import '@americastestkitchen/mise/mise-styles/main.scss';

.fullWidthVideoWrapper {
  width: 100%;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border: none;
  }
}

.wrapSpinner {
  position: relative;
}

.loaderSpinner {
  aspect-ratio: 16 / 9;
  background: #fff;
}

@media screen and (max-width: $miseBreakpointMd) {
  .fullWidthVideoWrapper {
    :global(.theo-mobile) {
      :global(.theo-menu-container) > * {
        transform: translateY(10px) !important;
        bottom: unset !important;
      }

      :global(.theo-menu-header) {
        padding: 2px !important;
      }

      :global(.theo-menu-item) {
        height: 2em !important;
        line-height: 1em!important;
      }
    }
  }
}
