@use '@americastestkitchen/mise/mise-styles/mise.scss';

.teamMemberCardWrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 308px;
  width: 273px;
  padding: 19px 21px;
  border-radius: 6px;
}

.teamMemberCardImage {
  height: 100px;
  width: 100px;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

.teamMemberCardName {
  @include mise.setFont(moret, null, null);
  font-size: 22px;
  line-height: 54px;
  text-align: center;
}

.teamMemberCardDek {
  @include mise.setFont(proximaNova, null, null);
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}
