@use '@americastestkitchen/mise/mise-styles/mise.scss';

.wrapper {
  text-align: center;
  color: mise.sourceColor(shade);

  :global(.accented) {
    text-underline-offset: 4px;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: mise.sourceColor(tomatoFalse);
    text-decoration-style: solid;
    -webkit-text-decoration-line:  underline !important;
    -webkit-text-decoration-color: #f4364c !important;
    -webkit-text-decoration-style: solid !important;
  }
  :global(.has-marker) {
    margin-bottom: 12px;
    :global(.decorated) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: -6px;
        right: -6px;
        height: 15px;
        background-image: url("./svg/decoration.svg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  :global(.has-highlight) {
    --underline-intrinsic-width: 8;
    --underline-color: #F8D6C7;
    --underline-cap-width: 4px;
    --underline-width: 24;
    --underline-offset-y: -2px;
    --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
    display: inline;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(180deg, var(--underline-color), var(--underline-color));
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size:
      calc(100% - calc(var(--underline-cap-width) * var(--underline-width-scale) * 0)) calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px);

  }
}

.header {
  @include mise.setFont(moret, null, null);
  font-size: 32px;
  line-height: 36.16px;
  margin-bottom: 12px;
}

.content {
  @include mise.setFont(proximaNova, null, null);
  font-size: 16px;
  line-height: 22.4px;
  width: 100%;

  a {
    background-image: linear-gradient(to top, transparent 3px, mise.sourceColor(werthers) 3px, mise.sourceColor(werthers) 5px, transparent 5px);

    &:focus,
    &:hover {
      background-image: linear-gradient(to top, transparent 3px, mise.sourceColor(werthers) 3px, mise.sourceColor(werthers) 5px, transparent 5px),
      linear-gradient(to top, transparent 3px, mise.sourceColor(werthersTint) 3px, mise.sourceColor(werthersTint) calc(100% - 3px), transparent calc(100% - 3px));
    }
  }
}
@media screen and (min-width: mise.breakpoint(md)) {
  .header {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 12px;
  }
  .content {
    font-size: 22px;
    line-height: 30px;
  }
  .wrapper {
    max-width: 916px;
    margin: auto;
    :global(.has-marker) {
      margin-bottom: 45px;
      :global(.decorated) {
        &::after {
          bottom: -14px;
          height: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: mise.breakpoint(lg)) {
  .header {
    margin-bottom: 17px;
  }
  .content {
    font-size: 24px;
    line-height: 30.9px;
  }
}
