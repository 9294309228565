@use '@americastestkitchen/mise/mise-styles/mise.scss';

.twoColumnTextContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: mise.breakpoint(md)) {
    display: grid;
    grid-template-areas:
      'image1 image2 '
      'image3 image2 ';
    grid-template-columns: 2fr 3fr;

    &.link {
      grid-template-rows: 0fr 1fr;
    }
  }

  .image1 {
    grid-area: image1;
  }

  .image2 {
    grid-area: image2;
  }

  .image3 {
    grid-area: image3;
    a {
      &:first-child {
        margin-bottom: 12px;
      }

    }
  }
}

.twoColumnTextHeadline {
  @include mise.setFont(moret, null, null);
  font-size: 27px;
  line-height: 34px;

  @media screen and (min-width: mise.breakpoint(md)) {
    font-size: 36px;
    line-height: 40px;
  }

  em {
    --underline-intrinsic-width: 8;
    --underline-width: 12;
    --underline-color: #F8D6C7;
    --underline-cap-width: 4px;
    --underline-offset-y: -2px;
  }

  em {
    display: inline;
    --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
    background-repeat: no-repeat;
    background-image:
      linear-gradient(180deg, var(--underline-color), var(--underline-color));
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size:
      calc(100% - calc(var(--underline-cap-width) * var(--underline-width-scale) * 0)) calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px);
    --underline-width: 20;
    --underline-offset-y: -2px;
    font-style: inherit;
  }

  span {
    display: block;
  }
}

.twoColumnTextDescription {
  @include mise.setFont(proximaNova, null, null);
  font-size: 16px;
  line-height: 22.4px;

  @media screen and (min-width: mise.breakpoint(md)) {
    font-size: 24px;
    line-height: 30.9px;
    max-width: 690px;
  }
}

.twoColumnTextLink {
  @include mise.setFont(proximaNova, null, null);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  border-radius: 24px;
  border: 1.5px solid mise.sourceColor(caviar);
  padding: 0 24px;
  height: 34px;
  width: fit-content;

  &.atk-button-styles {
    background-color: mise.sourceColor(tomato);
    border: unset;
    color: mise.sourceColor(white);

    &:hover {
      background-color: mise.sourceColor(tomatoShade);
    }
  }
}
