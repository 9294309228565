@use '@americastestkitchen/mise/mise-styles/mise.scss';

.wrapper {
  text-align: center;
  color: mise.color(neutral, dark, shade);
  border-bottom: 2px solid mise.sourceColor(tomatoFalse);
  width: 100%;
}

.header {
  @include mise.setFont(moret, null, null);
  font-size: 32px;
  line-height: 36.16px;
  padding-bottom: 24px;
}

@media screen and (min-width: mise.breakpoint(md)) {
  .header {
    font-size: 48px;
    line-height: 54px;
  }
}
