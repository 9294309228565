@use '@americastestkitchen/mise/mise-styles/mise.scss';

.twoUpCardWrapper {
  background-color: #ffffff;
  height: 577px;
  width: 275px;
  border-radius: 6px;
  position: relative;

  #image-array {
    display: flex;
    height: 635px;
  }

  @media screen and (min-width: mise.breakpoint(md)) {
    height: 595px;
    width: 560px;

    #image-array {
      height: 628px;
      width: 561px;
    }
  }
}

.twoUpCardImagesContainer {
  height: 315px;
  display: flex;
  width: 100%;

  img {
    border-radius: 6px 6px 0 0;
    height: 100%;
    width: 100%;
  }
}

.twoUpCardImage {
  height: 282px;
  width: 100%;

  img {
    border-radius: 6px 6px 0 0;
    height: 100%;
    width: 100%;
  }
}

.twoUpCardContent {
  padding: 17px 20px 18px 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: mise.breakpoint(md)) {
    padding: 26px;
  }
  .footer {
    position: absolute;
    bottom: 21px;
  }
}

.twoUpCardHeadline {
  @include mise.truncateLineClamp(3);
  @include mise.setFont(moret, null, null);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 9px;

  @media screen and (min-width: mise.breakpoint(md)) {
    @include mise.truncateLineClamp(2);
    font-size: 32px;
    line-height: 36.5px;
    margin-bottom: 18px;
  }
}

.twoUpCardDescription {
  @include mise.truncateLineClamp(5);
  @include mise.setFont(proximaNova, null, null);
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: auto;

  @media screen and (min-width: mise.breakpoint(md)) {
    line-height: 22px;
  }
}

.footer {
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}
