@use '@americastestkitchen/mise/mise-styles/mise.scss';

@mixin scroll-area {
  overflow-x: scroll;
  overflow-y: hidden;
  user-select: none;
  -webkit-user-drag: none;

  a,
  img {
    user-select: none !important;
    -webkit-user-drag: none !important;
  }

  img {
    pointer-events: none;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.standardCarousel {
  .header {
    align-items: flex-end;
    color: mise.sourceColor(caviar);
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 17px;
    position: relative;

    @media screen and (min-width: mise.breakpoint(md)) {
      margin-bottom: 31px;
    }

    @media screen and (min-width: mise.breakpoint(lg)) {
      margin-bottom: 33px;
    }

    .title {
      flex-grow: 1;
      font-family: 'Moret';
      font-size: 27px;
      font-weight: 700;
      line-height: 1.2;

      @media screen and (min-width: mise.breakpoint(md)) {
        font-size: 32px;
      }

      @media screen and (min-width: mise.breakpoint(md)) {
        font-size: 36px;
      }
    }

    .centered {
      margin: auto;
      text-align: center;
    }

    .buttons {
      align-items: flex-end;
      display: none;
      gap: 8px;
      justify-content: center;

      button {
        width: 32px;
        height: 32px;
        border-radius: 100%;
      }

      @media screen and (min-width: mise.breakpoint(xl)) {
        display: flex;
      }
    }
  }

  .anniverScrollHeader {
    margin-bottom: 17px;
  }
}

.carouselArea {
  padding: 4px;
  margin: -4px;
  $gap: 16px;

  @include scroll-area;

  display: grid;
  grid-auto-columns: max(162px, calc(25% - ($gap * 3 / 4)));
  grid-auto-flow: column;
  gap: $gap;
}

.twoUpDocCardCarouselWrapper {
  grid-auto-columns: 275px !important;
  gap: 16px !important;

  @media screen and (min-width: mise.breakpoint(md)) {
    grid-auto-columns: 560px !important;
  }
}

.carouselArea.teamMemberCarouselWrapper {
  $gap: 16px;
  grid-auto-columns: max(273px, calc(25% - ($gap * 3 / 4)));
}

.carouselArea.peekCardCarouselWrapper {
  $gap: 16px;
  grid-auto-columns: max(273px, calc(25% - ($gap * 3 / 4)));
}
