@use '@americastestkitchen/mise/mise-styles/mise.scss';

$authorImageSize: 3rem; //2.5rem;

.author-image {
  display: block;
}

.author-image,
.author-initial-container {
  border-radius: calc($authorImageSize / 2);
  height: $authorImageSize;
  min-width: $authorImageSize;
}

.author-initial-container {
  display: flex;
  align-items: center;
  color: mise.sourceColor(white);
  background-color: mise.sourceColor(caviar),
}

.author-initials {
  @include mise.setFont(proximaNova, null, null);
  font-size: 1.4rem;
  line-height: 1.625;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
}