@use '@americastestkitchen/mise/mise-styles/mise.scss';

.fullWidthImageCarouselWrapper {
  position: relative;
  width: 100%;
}

.fullWidthImageWrapper {
  margin-bottom: 12px;
  width: 100%;

  img {
    width: 100%;
  }

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 40px;
  }
}

.focus-active-items {
  display: flex;
  gap: 8px;
  justify-content: center;

  button {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #E8593B;
    color: #000;
    height: 10px;
    max-width: 10px;
    transition: max-width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    width: 30px;

    &:focus {
      outline-color: mise.color(neutral,dark);
    }

    &.active {
      background-color: mise.sourceColor(tomato);
      border-radius: 10em;
      color: white;
      max-width: 30px;
    }
  }
}

.directional-active-items {
  bottom: -6px;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: absolute;
  right: 15px;

  svg {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: mise.breakpoint(md)) {
    bottom: -10px;
    gap: 12px;
    right: 24px;
  }

  button {
    height: 25px;
    width: 25px;

    @media screen and (min-width: mise.breakpoint(md)) {
      height: 32px;
      width: 32px;
    }
  }
}
